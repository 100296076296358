import React from "react";

const SVG = (props) => (
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    version="1.1"
    className=''
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Global-/-Header"
        transform="translate(-1214.000000, -67.000000)"
        fill={props.color}
      >
        <g id="Group" transform="translate(1214.000000, 67.000000)">
          <g id="list/deactive">
            <path
              d="M1,0 L5,0 C5.55228475,-1.01453063e-16 6,0.44771525 6,1 L6,2 C6,2.55228475 5.55228475,3 5,3 L1,3 C0.44771525,3 6.76353751e-17,2.55228475 0,2 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 Z M1,6.5 L5,6.5 C5.55228475,6.5 6,6.94771525 6,7.5 L6,8.5 C6,9.05228475 5.55228475,9.5 5,9.5 L1,9.5 C0.44771525,9.5 6.76353751e-17,9.05228475 0,8.5 L0,7.5 C-6.76353751e-17,6.94771525 0.44771525,6.5 1,6.5 Z M1,13 L5,13 C5.55228475,13 6,13.4477153 6,14 L6,15 C6,15.5522847 5.55228475,16 5,16 L1,16 C0.44771525,16 6.76353751e-17,15.5522847 0,15 L0,14 C-6.76353751e-17,13.4477153 0.44771525,13 1,13 Z M8.5,1 L15.5,1 C15.7761424,1 16,1.22385763 16,1.5 C16,1.77614237 15.7761424,2 15.5,2 L8.5,2 C8.22385763,2 8,1.77614237 8,1.5 C8,1.22385763 8.22385763,1 8.5,1 Z M8.5,7.5 L15.5,7.5 C15.7761424,7.5 16,7.72385763 16,8 C16,8.27614237 15.7761424,8.5 15.5,8.5 L8.5,8.5 C8.22385763,8.5 8,8.27614237 8,8 C8,7.72385763 8.22385763,7.5 8.5,7.5 Z M8.5,14 L15.5,14 C15.7761424,14 16,14.2238576 16,14.5 C16,14.7761424 15.7761424,15 15.5,15 L8.5,15 C8.22385763,15 8,14.7761424 8,14.5 C8,14.2238576 8.22385763,14 8.5,14 Z"
              id="Combined-Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default SVG;
